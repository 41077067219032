import React from 'react'
import { Button, Form, Table, Icon, Element } from 'react-bulma-components'
import { faExclamationTriangle, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudinaryImage from '../cloudinary/image'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import SearchComponent from '../form/search-component'
import { useSession } from '../../stores/session'
import ProductStock from '../products/stock'
import { roundNumbersInObjects } from '../../utils/numbers'


const Basket = ({ basket, addItemToBasket, removeItemFromBasket, priceRequest, light }) => {
  const [sessionState] = useSession()

  const { t } = useTranslation()

  const totals = React.useMemo(() => {
    const totals = { products: 0, bebat: 0, auvibel: 0, recupel: 0, discount: 0, vat: 0, total: 0, totalVat: 0 }

    basket.forEach(basketItem => {
      if (!sessionState.currentUser && basketItem.product)
        totals.products += basketItem.product.pricing.srp * basketItem.quantity
      else if (basketItem.product && basketItem.pricing && basketItem.pricing.PHTAX)
        totals.products += basketItem.pricing.PHTAX * basketItem.quantity

      if (basketItem.product && basketItem.product.pricing.bebat)
        totals.bebat += basketItem.product.pricing.bebat * basketItem.quantity
      if (basketItem.product && basketItem.product.pricing.auvibel)
        totals.bebauvibelaauvibelt += basketItem.product.pricing.auvibel * basketItem.quantity
      if (basketItem.product && basketItem.product.pricing.recupel)
        totals.recupel += basketItem.product.pricing.recupel * basketItem.quantity
    })

    totals.total = totals.products + totals.auvibel + totals.recupel + totals.bebat
    totals.totalVat = totals.total * 1.21
    totals.vat = totals.totalVat - totals.total

    roundNumbersInObjects(totals, 2)

    totals.totalVat = totals.total * 1.21
    totals.vat = totals.totalVat - totals.total

    roundNumbersInObjects(totals, 2)
    return totals
  }, [basket])

  return (
    <Element>
      <Element style={{ maxWidth: 400, }} mb={3}>
        <Element mb={1}>{t('basket:add_product')}:</Element>
        <SearchComponent
          width='400px'
          onChange={(value) => !basket.some(i => i.item === value.value) && addItemToBasket({
            item: value.value,
            quantity: 1
          }) }
          onlyProduct
        />
      </Element>
      <Table.Container mb={1}>
        <Table hoverable size='fullwidth'>
          <thead>
            <Element textWeight='bold' renderAs='tr'>
              <Element renderAs='td'>
                {t('basket:product')}
              </Element>
              <Element renderAs='td'>{t('basket:description')}</Element>
              { !light && <Element renderAs='td'>{t('basket:stock')}</Element> }
              {
                !light &&
                  <Element renderAs='td' textAlign='right'>{t('basket:unit_price')}</Element>
              }
              { sessionState.currentUser && !light && <Element renderAs='td' textAlign='right'>{t('basket:discount')}</Element> }
              { sessionState.currentUser && <Element renderAs='td' textAlign='right'>{t('basket:net_price')}</Element> }
              <Element renderAs='td' textAlign='right'>{t('basket:quantity')}</Element>
              {/*
                !light &&
                  <Element renderAs='td'>
                    <Tag
                      size='medium'
                      color='white'
                    >
                      <Icon
                        color='warning'
                      >
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                        />
                      </Icon>
                    </Tag>
                  </Element>
              */}
              <Element renderAs='td' textAlign='right'>{t('basket:total_label')}</Element>
              {
                !light &&
                  <Element renderAs='td'></Element>
              }
            </Element>
          </thead>
          <tbody>
            {
              basket.map((basketItem) =>
                basketItem.product &&
                  <tr key={ basketItem.product._id }>
                    <Element renderAs='td'>
                      <CloudinaryImage
                        publicId={basketItem.product.pictures[0] && basketItem.product.pictures[0].item.providerId}
                        transformations={{
                          width: '60',
                          height: '60',
                          crop: 'thumb',
                        }}
                      />
                    </Element>
                    <Element renderAs='td'>
                      <Link to={`/products/${basketItem.product._id}/`}>
                        { basketItem.product.name.fr }
                      </Link>
                      <br />
                      {
                        basketItem.product &&
                          <>
                            {Boolean(basketItem.product.pricing.recupel) &&
                              <Element textSize={7}>Recupel: {basketItem.product.pricing.recupel} €</Element>
                            }
                            {Boolean(basketItem.product.pricing.auvibel) &&
                              <Element textSize={7}>Auvibel: {basketItem.product.pricing.auvibel} €</Element>
                            }
                            {Boolean(basketItem.product.pricing.bebat) &&
                              <Element textSize={7}>Bebat: {basketItem.product.pricing.bebat} €</Element>
                            }
                          </>
                      }
                    </Element>
                    {
                      !light &&
                        <Element renderAs='td'>
                          { (basketItem.product.stock.available === 0 || basketItem.product.stock.available < basketItem.quantity) && !sessionState.currentUser &&
                            <Element renderAs='span' mr={1} textColor='danger'><FontAwesomeIcon icon={faExclamationTriangle} /></Element>
                          }
                          <ProductStock currentUser={sessionState.currentUser} stock={basketItem.product.stock.available}/>
                        </Element>
                    }
                    {
                      !light &&
                        <Element renderAs='td' textAlign='right'>
                          { basketItem.product && (sessionState.currentUser ? basketItem.product.pricing.price : basketItem.product.pricing.srp).toFixed(2) }&nbsp;€
                        </Element>
                    }
                    {
                      sessionState.currentUser && !light && <Element renderAs='td' textAlign='right'>{basketItem.pricing?.REMX}%</Element>
                    }
                    {
                      sessionState.currentUser &&
                        <Element renderAs='td' textAlign='right'>
                          {
                            priceRequest && priceRequest.status === 'inProgress' ?
                              <FontAwesomeIcon icon={faSpinner} spin/> :
                              <span>{basketItem.pricing && basketItem.pricing.PHTAX}&nbsp;€</span>
                          }
                        </Element>
                    }
                    <Element renderAs='td' textAlign='right'>
                      {
                        light ?
                          <span>{basketItem.quantity}</span>
                          :
                          <Form.Input
                            size='small'
                            type='number'
                            min={1}
                            value={basketItem.quantity}
                            style={{ width: '4.5rem' }}
                            onChange={ (e) => addItemToBasket({
                              item: basketItem.item,
                              quantity: Number(e.target.value) || 1
                            }) }
                          />
                      }
                    </Element>
                    {/*
                      !light &&
                        <Element renderAs='td'>
                          <Tag.Group
                            hasAddons
                            data-tooltip='Commandez 32 produits pour obtenir une remise'
                          >
                            <Tag size='medium' color='white'>
                              <Icon
                                color='warning'
                              >
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                />
                              </Icon>
                            </Tag>
                            <Tag
                              color='warning'
                              size='medium'
                            >
                              32
                            </Tag>
                          </Tag.Group>
                        </Element>
                    */}
                    <Element renderAs='td' textAlign='right'>
                      {
                        priceRequest && priceRequest.status === 'inProgress' ?
                          <FontAwesomeIcon icon={faSpinner} spin/> :
                          <span>
                            { sessionState.currentUser && basketItem.pricing && (basketItem.pricing.PHTAX * basketItem.quantity).toFixed(2) }&nbsp;€
                            { !sessionState.currentUser && ((basketItem.product.pricing.srp).toFixed(2) * basketItem.quantity).toFixed(2)}

                            {Boolean(basketItem.product.pricing.recupel) &&
                              <Element textSize={7}>{(basketItem.product.pricing.recupel * basketItem.quantity).toFixed(4)} €</Element>
                            }
                            {Boolean(basketItem.product.pricing.auvibel) &&
                              <Element textSize={7}>{(basketItem.product.pricing.auvibel * basketItem.quantity).toFixed(4)} €</Element>
                            }
                            {Boolean(basketItem.product.pricing.bebat) &&
                              <Element textSize={7}>{(basketItem.product.pricing.bebat * basketItem.quantity).toFixed(4)} €</Element>
                            }
                          </span>
                      }
                    </Element>
                    {
                      !light &&
                        <Element renderAs='td'>
                          <Icon>
                            <Button
                              color='grey'
                              size='small'
                              onClick={ () => removeItemFromBasket({ item: basketItem.item }) }
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                              />

                            </Button>
                          </Icon>
                        </Element>
                    }
                  </tr>
              )
            }
          </tbody>
        </Table>
      </Table.Container>
      {/*
        !light &&
          <Message
            pull='left'
            color='warning'
          >
            <Message.Body>
              <Icon
                color='warning'
                style={{ position: 'relative', top: 1 }}
                mr={4}
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Icon>
              Attention, des remises sont disponibles pour certains volumes de commande
            </Message.Body>
          </Message>
      */}
      <Element textAlign='right' pr={3}>
        {
          priceRequest && priceRequest.status === 'inProgress' ?
            <FontAwesomeIcon icon={faSpinner} spin/> :
            <Element>
              <span>
                {t('basket:total_products')}: { totals.products }&nbsp;€
              </span>
              <Element textSize={7}>
                <span>
                  Recupel: { totals.recupel }&nbsp;€
                </span>
              </Element>
              <Element textSize={7}>
                <span>
                  Bebat: { totals.bebat }&nbsp;€
                </span>
              </Element>
              <Element textSize={7}>
                <span>
                  Auvibel: { totals.auvibel }&nbsp;€
                </span>
              </Element>
              <Element mt={2}>
                <span>
                  {t('basket:total_vat_excl')}: { totals.total }&nbsp;€
                </span>
              </Element>
              <Element>
                <span>
                  {t('basket:vat')} (21%): { totals.vat }&nbsp;€
                </span>
              </Element>
              <Element textWeight='bold' mt={2}>
                <span>
                  {t('basket:total')}: { totals.totalVat }&nbsp;€
                </span>
              </Element>
            </Element>
        }
      </Element>
    </Element>
  )
}

export default Basket