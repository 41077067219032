import { round } from 'lodash'

export const roundNumbersInObjects = (
  obj,
  precision,
) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') roundNumbersInObjects(obj[key])
    else obj[key] = round(obj[key], precision || 2)
  }
  return obj
}
