import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Level, Columns, Container, Heading, Section } from 'react-bulma-components'
import { useSession } from '../stores/session'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import Basket from '../components/basket/basket'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const BasketPage = () => {
  const [sessionState, sessionActions] = useSession()
  React.useEffect(() => {
    sessionActions.loadBasket()
  }, [])
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const priceRequest = sessionState.requests && sessionState.requests['post /products/prices']

  const CheckoutButton = () => {
    const disabled = (priceRequest && priceRequest.status === 'inProgress') || !sessionState.basket[0]
    return (
      <Button
        renderAs={disabled ? 'button' : Link}
        to='/order-info'
        color='primary'
        disabled={ disabled}
      >
        Checkout
      </Button>
    )
  }

  return (
    <Layout>
      
      <Section size='medium'>
        <Container>
          <Level>
            <Level.Side>
              <Level.Item mb={3}>
                <Heading size={4}>
                  {t('basket:title')}
                </Heading>
              </Level.Item>
            </Level.Side>
            <Level.Side>
              <Level.Item>
                <Button renderAs={Link} to='/all-products' mr={2} color='dark' outlined>← {t('basket:return_to_shop')}</Button>
                <CheckoutButton />
              </Level.Item>
            </Level.Side>
          </Level>
          <Columns centered gap={8}>
            <Columns.Column>
              <Basket
                basket={ sessionState.basket }
                addItemToBasket={ sessionActions.addItemToBasket }
                removeItemFromBasket={ sessionActions.removeItemFromBasket }
                priceRequest={ priceRequest }
              />
            </Columns.Column>
          </Columns>
          <Level>
            <Level.Side>
            </Level.Side>
            <Level.Side>
              <Level.Item>
                <CheckoutButton />
              </Level.Item>
            </Level.Side>
          </Level>
        </Container>
      </Section >
    </Layout >
  )
}

export default BasketPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`